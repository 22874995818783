import { removeLocalStorage, setUserName, userToken } from "../contexts/auth";

export const isLoggedIn = () => {
  return userToken().length > 0;
};

export const logout = () => {
  removeLocalStorage();
  setUserName("Guest");
};
