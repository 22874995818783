import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  TournamentRecord,
  deleteTournament,
  getTournaments,
  startTournament,
} from "../api/tournaments";
import { queryClient } from "../api/query";
import ConfirmDialog from "../components/ConfirmDialog";
import { useState } from "react";
import { adminStatus } from "../contexts/auth";

const payment12 = "https://buy.stripe.com/8wMcPm1cU6ZE8gg5kk";
const payment20 = "https://buy.stripe.com/9AQ6qYcVC83IdAA001";

// const payment12 = "https://buy.stripe.com/test_9AQdRPaoP7yZ6v6cMM";
// const payment20 = "https://buy.stripe.com/test_cN27treF5dXn8De289";

const statusCell = (tournament: TournamentRecord, startTourney: () => void) => {
  if (tournament.complete) {
    return <Chip label="Completed" color="secondary" />;
  }

  if (tournament.started) {
    return <Chip label="Active" color="secondary" />;
  }

  const readyToStart =
    tournament.payment_id > 0 || tournament.players < 12 || adminStatus();
  if (readyToStart) {
    return (
      <Button variant="contained" onClick={startTourney}>
        Start
      </Button>
    );
  }

  console.log("You need to pay to start the tournament");
  console.log(tournament);
  console.log("adminStatus", adminStatus());

  const paymentBase = tournament.players > 16 ? payment20 : payment12;
  const paymentUrl = paymentBase + "?client_reference_id=" + tournament.token;

  return (
    <Button
      variant="contained"
      onClick={() => (window.location.href = paymentUrl)}
    >
      Pay {tournament.players > 16 ? "$10" : "$5"} to Start
    </Button>
  );
};

export default function TourneyList() {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [tournamentToken, setTournamentToken] = useState<string>("");

  const navigate = useNavigate();
  const { isLoading, isError, data } = useQuery({
    queryKey: ["tournaments"],
    queryFn: () => getTournaments(),
  });

  const startTourneyMutation = useMutation({
    mutationFn: startTournament,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tournaments"]);
    },
    onError: (error) => {
      console.error("ERROR:", error);
    },
  });

  const deleteTourneyMutation = useMutation({
    mutationFn: deleteTournament,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["tournaments"]);
    },
    onError: (error) => {
      console.error("ERROR:", error);
    },
  });

  const order = (a: TournamentRecord, b: TournamentRecord) => {
    if (a.complete && !b.complete) {
      return 1;
    }
    if (!a.complete && b.complete) {
      return -1;
    }
    if (a.started && !b.started) {
      return 1;
    }
    if (!a.started && b.started) {
      return -1;
    }
    return a.name.localeCompare(b.name);
  };

  if (isError) {
    navigate("/");
    return <h1>Error, please login.</h1>;
  }

  return isLoading ? (
    <h1>Loading...</h1>
  ) : (
    <>
      <h1>Tournament List</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Status</TableCell>
              <TableCell style={{ minWidth: 250 }} component="th">
                Name
              </TableCell>
              <TableCell align="right">Players</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.tournaments.sort(order).map((row: TournamentRecord) => (
              <TableRow key={row.token}>
                <TableCell align="left">
                  {statusCell(row, () =>
                    startTourneyMutation.mutate(row.token),
                  )}
                </TableCell>
                <TableCell>
                  <Link
                    to={"/tournament/" + row.token}
                    onClick={() => {
                      setTournamentToken(row.token);
                    }}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell align="right">{row.players}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setTournamentToken(row.token);
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        title="Delete Tournament?"
        open={confirmDelete}
        setOpen={setConfirmDelete}
        onConfirm={() => {
          deleteTourneyMutation.mutate(tournamentToken);
        }}
      >
        Are you sure you want to delete this tournament?
      </ConfirmDialog>
    </>
  );
}
