import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";

const Pricing: React.FC = () => {
  const pricingPlans = [
    {
      title: "Free",
      price: "$0",
      features: ["4 player tournaments", "8 player tournaments"],
    },
    {
      title: "Normal",
      price: "$5",
      features: ["12 player tournaments", "16 player tournaments"],
      highlight: true,
    },
    {
      title: "The more the merrier",
      price: "$10",
      features: ["Any multiple of 4 player tournaments"],
    },
  ];

  return (
    <Box
      sx={{
        textAlign: "center",
        p: 4,
        minHeight: "100vh",
      }}
    >
      <Typography variant="h3" gutterBottom>
        Pricing
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Please take sometime and create one or more small tournaments for free.
        See what we do and want we don't. Let us know what you think.
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
        {pricingPlans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                borderRadius: "16px",
                boxShadow: plan.highlight
                  ? "0 4px 20px rgba(0, 0, 0, 0.2)"
                  : "0 2px 10px rgba(0, 0, 0, 0.1)",
                border: plan.highlight ? "2px solid #3f51b5" : "none",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: plan.highlight ? "#3f51b5" : "textPrimary",
                  }}
                >
                  {plan.title}
                </Typography>
                <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
                  {plan.price}/tournament
                </Typography>
                <Box>
                  {plan.features.map((feature, i) => (
                    <Typography key={i} variant="body1" sx={{ mb: 1 }}>
                      {feature}
                    </Typography>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Pricing;
