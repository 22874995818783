export const loginUser = async (access_token: string) => {
  return fetch("/api/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ access_token }),
  }).then((data) => data.json());
};

export const registerUser = async (access_token: string) => {
  const data = await fetch("/api/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ access_token }),
  });
  return await data.json();
};
