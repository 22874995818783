import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { userToken } from "../contexts/auth";
import { useMutation } from "react-query";
import { queryClient } from "../api/query";

export default function CreateTourney() {
  const navigate = useNavigate();

  async function createTourney({
    name,
    players,
    rounds,
    buy_in,
    euchre_cost,
    payouts,
  }: {
    name: string;
    players: number;
    rounds: number;
    buy_in: number;
    euchre_cost: number;
    payouts: number;
  }) {
    return fetch("/api/tournament", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${userToken()}`,
      },
      body: JSON.stringify({
        name,
        players,
        rounds,
        buy_in,
        euchre_cost,
        payouts,
      }),
    }).then((data) => data.json());
  }

  const createTourneyMutation = useMutation({
    mutationFn: createTourney,
    onSuccess: (data) => {
      console.log("Success:", data);
      queryClient.invalidateQueries(["tournaments"]);
      if ("token" in data) {
        navigate("/tournaments");
      } else {
        console.log("ERROR:", data);
      }
    },
    onError: (error) => {
      console.log("ERROR:", error);
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name: string = data.get("name") as string;
    const player_count: string = data.get("player-count") as string;
    const players = parseInt(player_count);
    const rounds = parseInt(data.get("rounds") as string);
    const buy_in = parseInt(data.get("buy-in") as string);
    const euchre_cost = parseInt(data.get("euchre-cost") as string);
    const payouts = parseInt(data.get("payouts") as string);

    createTourneyMutation.mutate({
      name,
      players,
      rounds,
      buy_in,
      euchre_cost,
      payouts,
    });
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid item md={2} xs={false} />
      <Grid item xs={12} md={8} component={Paper} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Tournament
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="tournament-name"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="number"
              id="players"
              label="Player Count"
              name="player-count"
              autoComplete="player-count"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              type="number"
              id="rounds"
              label="Number of rounds"
              name="rounds"
              autoComplete="rounds"
            />
            <TextField
              margin="normal"
              fullWidth
              type="number"
              id="buy-in"
              label="Buy in"
              name="buy-in"
              autoComplete="rounds"
            />
            <TextField
              margin="normal"
              fullWidth
              type="number"
              id="euchre-cost"
              label="Euchre cost"
              name="euchre-cost"
              autoComplete="rounds"
            />
            <TextField
              margin="normal"
              fullWidth
              type="number"
              id="payouts"
              label="Payouts"
              name="payouts"
              autoComplete="rounds"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => {}}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
