import React from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { isLoggedIn } from "../data/authEffects";

const startURL = isLoggedIn() ? "/create-tourney" : "/register";

const Home: React.FC = () => {
  return (
    <Box
      sx={{
        minHeight: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        py: 6,
      }}
    >
      <Container maxWidth="lg">
        {/* Hero Section */}
        <Grid container spacing={4} alignItems="center">
          {/* Text Content */}
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>
              Welcome to Euchre Tourney
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Euchre Tourney is designed to make hosting and participating in
              Euchre tournaments easier than ever before. Just pick the number
              of players, either enter everyone's name or have them join and do
              it themselves, and let the app do the rest.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Discover how easy and fast your next Euchre tournament can be.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              href={startURL}
            >
              Get Started
            </Button>
          </Grid>

          {/* Video Content */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                pt: "56.25%", // 16:9 Aspect Ratio
                borderRadius: 2,
                overflow: "hidden",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <video
                src="/media/euchre.mp4"
                autoPlay
                loop
                muted
                controls
                playsInline
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              ></video>{" "}
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* Features Section */}
      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {[
            {
              title: "Schedule",
              description:
                "We build a perfect schedule, where no one is ever your partner more than once or your opponent more than twice.",
            },
            {
              title: "Scoring",
              description:
                "Easy to use phone interface makes live scoring simple.",
            },
            {
              title: "Rankings",
              description:
                "Your players score, and a live leaderboard is yours for free.",
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
