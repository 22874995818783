import { useQuery } from "react-query";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { tournament } from "../data/tourney";
import { setTournamentToken, tournamentToken } from "../contexts/auth";
import { getTournament } from "../api/tournaments";
import { RoundType, SimplePlayerType } from "../data/types";

export default function TournamentNames() {
  const navigate = useNavigate();
  const token =
    useParams<{ tournamentToken: string }>()["tournamentToken"] || "";

  if (token.length > 0 && tournamentToken() !== token) {
    console.log("Setting tournamentToken to", token);
    setTournamentToken(token);
  }

  const { isLoading, isError, data } = useQuery({
    queryKey: ["tournament", tournamentToken()],
    queryFn: () => getTournament(),
    onSuccess: (data) => {
      console.log("Success:", data);
      if (data?.status !== "success") {
        console.log("ERROR:", data);
        throw new Error("Error loading tournament");
      }
    },
    onError: (error) => {
      navigate("/signin");
    },
  });

  if (isLoading) return <h1>Loading...</h1>;
  if (isError) return <h1>Error, please refresh</h1>;

  const players = data.players as SimplePlayerType[];
  const playersById = players.reduce((acc, player) => {
    acc[player.id] = player;
    return acc;
  }, {} as Record<number, SimplePlayerType>);

  console.log("round count:", data.rounds.length);
  const roundsByRound = data.rounds.reduce(
    (acc: RoundType[][], round: RoundType) => {
      acc[round.round] = acc[round.round] || [];
      acc[round.round].push(round);
      return acc;
    },
    [] as RoundType[][],
  );
  console.log("PlayersById:", playersById);
  console.log("data:", data);
  console.log("RoundsByRound:", roundsByRound);

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h2">
            Tournament {tournament.value.name} Schedule
          </Typography>
        </Grid>
        {roundsByRound.map((round: RoundType[]) => (
          <Grid
            container
            xs={12}
            sx={{
              paddingTop: "12px",
              paddingBottom: "6px",
              breakInside: "avoid",
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h4">Round {round[0].round + 1}</Typography>
            </Grid>
            {round.map((r) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                  raised={true}
                  sx={{
                    margin: "12px",
                  }}
                >
                  <CardContent>
                    <CardHeader
                      title={`Table ${r.tablenum + 1}`}
                      sx={{ paddingLeft: 0 }}
                    />
                    <Typography align="center">
                      {playersById[r.north_player].name}
                      {" & "}
                      {playersById[r.south_player].name}
                    </Typography>
                    <Typography align="center">vs</Typography>
                    <Typography align="center">
                      {playersById[r.east_player].name}
                      {" & "}
                      {playersById[r.west_player].name}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
