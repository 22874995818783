import { tournamentToken, userToken } from "../contexts/auth";

export const createTournament = async ({
  email,
  name,
  password,
}: {
  email: string;
  name: string;
  password: string;
}) => {
  const data = await fetch("/api/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, name, password }),
  });
  return await data.json();
};

export type TournamentRecord = {
  token: string;
  name: string;
  started: boolean;
  complete: boolean;
  players: number;
  payment_id: number;
};

export const getTournaments = async () => {
  const result = await fetch("/api/tournaments", {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken(),
    },
  });
  const jsonResult = await result.json();

  if (result.status !== 200) {
    console.log("Failed to get tournaments", result, jsonResult);
    throw new Error("Failed to get tournaments: " + jsonResult.message);
  }

  return jsonResult;
};

export const startTournament = async (tournament_token: string) => {
  console.log("Starting tournament", userToken(), tournament_token);
  const result = await fetch(`/api/tournament/${tournament_token}/start`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken(),
    },
  });
  const jsonResult = await result.json();

  if (jsonResult.status === "error") {
    throw new Error(jsonResult.message);
  }

  return jsonResult;
};

export const deleteTournament = async (tournament_token: string) => {
  const result = await fetch(`/api/tournament/${tournament_token}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken(),
    },
  });
  const jsonResult = await result.json();

  if (jsonResult.status === "error") {
    throw new Error(jsonResult.message);
  }

  return jsonResult;
};

export const getTournament = async () => {
  console.log("getTournament");
  const result = await fetch(`/api/tournament/${tournamentToken()}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: userToken(),
    },
  });
  const jsonResult = await result.json();

  if (jsonResult.status !== "success") {
    throw new Error(jsonResult.message);
  }

  return jsonResult;
};
